.button.button-link {
  background: transparent;
  color: var(--button-background);
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;

  fstr-icon {
    min-width: 1.5em;
    min-height: 1.5em;

    .icon {
      fill: currentColor;
    }
  }

  @media (hover: hover) {
    &:hover {
      background: transparent;
      color: var(--button-background-darker);
    }
  }

  &.button--light {
    --button-background: var(--bs-light);
    --button-background-darker: var(--bs-dark);
  }
}
