.footer {
  background: rgba($purple, 0.2);

  &__logo fstr-icon {
    width: 170px;
    height: 55px;
    margin-bottom: 1rem;
  }

  &__contact {
    a {
      color: inherit;
    }
    .col-md-6 {
      white-space: pre;
    }
  }

  &__title {
    @include media-breakpoint-up(md) {
      margin-bottom: 3rem;
    }
  }

  &-link {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__nav-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__socials {
    @include media-breakpoint-down(md) {
      padding-top: 2rem;
    }
  }

  &__socials__list {
    justify-content: center;
    @include media-breakpoint-up(md) {
      justify-content: flex-end;
    }
  }
}
