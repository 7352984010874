.news-card {
  @media (hover: hover) {
    img {
      transition: transform 0.2s ease-in-out;
      transform: scale(1);
    }

    &:hover img {
      transform: scale(1.01);
    }

    &:hover {
      .button--dark {
        background-color: var(--bs-dark);
      }
    }
  }

  &__title {
    font-size: 1.1rem;
    color: var(--bs-dark);
  }
}
