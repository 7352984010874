.card {
  width: 100%;

  --bs-card-border-width: 0;
  --bs-card-border-radius: 0;
  --bs-card-box-shadow: none;
  --bs-card-bg: transparent;
  --bs-card-cap-bg: none;
  --card-title-color: var(--bs-light);
  --card-footer-icon-color: var(--bs-light);
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;

  position: relative;
  box-shadow: var(--bs-card-box-shadow);
  overflow: hidden;
  transition: transform 0.2s ease-in-out;

  &.card--shadow {
    box-shadow: $box-shadow;
  }

  &.card--shadow {
    --bs-card-box-shadow: #{$box-shadow};
  }
  &.card--shadow-sm {
    --bs-card-box-shadow: #{$box-shadow-sm};
  }
  &.card--shadow-lg {
    --bs-card-box-shadow: #{$box-shadow-lg};
  }
  &.card--shadow-xl {
    --bs-card-box-shadow: #{$box-shadow-xl};
  }

  &-title {
    color: var(--card-title-color);
  }

  &-footer {
    .icon {
      color: var(--card-footer-icon-color);
    }
  }
}
