em,
i {
  font-style: italic;
}

strong {
  font-weight: 800;
}

@each $name, $size in $markdown-font-sizes {
  * + #{$name},
  * + .#{$name} {
    margin-top: map-get($spacers, 6);
  }

  #{$name},
  .#{$name} {
    font-size: rem($size);
    margin-top: 1.25em;
    margin-bottom: 0.5em;
    font-weight: 500;
    //color: var(--bs-primary);
  }
}

ul,
ol {
  list-style: outside;
  padding-inline-start: map-get($spacers, 4);
  display: inline-block;
  margin-bottom: rem(20px);
  width: 100%;

  li {
    display: list-item;
    list-style-type: initial;
    font-weight: 400;
  }
}

ol li {
  list-style-type: decimal;
}

hr {
  margin: 1.5em 0;
}

p {
  margin-bottom: 3ex;

  &:last-child {
    margin-bottom: 0;
  }
}

pre {
  white-space: pre-line;
  font-family: monospace;
  background: var(--bs-gray-100);
  padding: 1em;
  border-radius: 7px;
  margin-bottom: 1.5em;
}

.button + .button {
  margin-inline-start: 0.5em;
}

a:not(.button) {
  color: var(--bs-primary);

  @media (hover: hover) {
    &:hover {
      color: darkenColor(var(--bs-primary), 0.2);
    }
  }
}
