// Imports
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

// Bootstrap
$font-size-base: 16;

$font-family-sans-serif: 'Open Sans', sans-serif;
$font-family-serif: 'Open Sans', serif;

// Tip: Use goldenratio to generate font base sizes
// https://type-scale.com/
$font-sizes: (
  1: 50px,
  2: 40px,
  3: 32px,
  4: 24px,
  5: 20px,
  6: 16px,
  body-xxs: 12px,
  body-xs: 14px,
  body-sm: 16px,
  body-md: 20px,
  body-lg: 26px,
);

$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 50px,
  5: 3rem,
  6: 2.5rem,
);

$headings-font-family: $font-family-sans-serif;
$headings-font-weight: 400;
$headings-line-height: 1.5;

$display-font-family: $font-family-serif;
$display-font-style: null;
$display-font-weight: 400;
$display-line-height: $headings-line-height;

$font-family-base: $font-family-sans-serif;
$font-weight-base: 400;

$lead-font-size: map-get($font-sizes, body-md);
$lead-font-weight: 400;
$lead-line-height: 1.8;

$text-muted: var(--bs-gray-600);

// Other
//? Put own extra variables here
