.block-image {
  // --block-spacing: 40px;
  // padding: var(--block-spacing);

  &__figure {
    // flex-direction: row-reverse;

    img {
      object-fit: cover;
      max-height: 550px;
    }
  }

  &__figure__caption {
    // text-align: left;

    @include media-breakpoint-up(md) {
      padding-left: 3rem;
    }
  }

  &.block-image-right {
    .block-image__figure {
      flex-direction: row-reverse;
    }

    .block-image__figure__caption {
      // text-align: right;

      @include media-breakpoint-up(md) {
        padding-right: 3rem;
        padding-left: 0;
      }
    }
  }

  &.block-image-small {
    .block-image__figure {
      img {
        aspect-ratio: 4/3;
        max-height: 250px;
      }
    }
  }
}
