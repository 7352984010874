.topbar {
  .container {
    justify-content: space-between;
  }
  &__logo {
    width: 267px;
    height: 86px;
    margin-right: 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__nav__list {
    align-items: center;
  }

  .is--current {
    color: var(--bs-dark);

    @include media-breakpoint-down(md) {
      color: var(--bs-secondary);
    }
  }

  @include media-breakpoint-down(md) {
    position: sticky;
    top: 0;
    padding: 0.5rem 0;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(4px);

    &__logo {
      width: 120px;
      height: 40px;
    }

    &__nav {
      position: absolute;
      top: 55px;
      width: 100%;
      // margin-left: -0.75rem;
      left: 0;
      width: 100vw;
      padding: 1rem;
      background-color: var(--bs-primary);
      opacity: 0;
      pointer-events: none;
      transform: scaleY(0);
      transform-origin: top center;
      transition:
        opacity 0.2s ease-in-out,
        transform 0.2s ease-in-out;

      .main-nav--active & {
        pointer-events: all;
        opacity: 1;
        transform: scaleY(1);
      }

      &__list {
        flex-direction: column;
        // align-items: flex-start;
      }
    }

    .hamburger-toggle {
      margin-top: -1.5rem;
    }
  }
}
