.block-team {
  &__main {
    overflow: hidden;
  }

  &__list {
    @media (hover: hover) {
      .block-team__list__item {
        transition:
          opacity 0.3s ease-in-out,
          filter 0.3s ease-in-out;
      }
      &:hover .block-team__list__item {
        opacity: 0.6;
        filter: blur(1px);
      }

      .block-team__list__item:hover {
        opacity: 1;
        filter: blur(0);
      }
    }
  }
}
:host .swiper {
  overflow: inherit;
}
