.block-partner {
  width: 100%;
}

.partner {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 200px;
  }
}
