.swatch {
  display: inline-block;
  aspect-ratio: 1/1;
  min-width: 3em;
  border-radius: 50%;
}

.swatch-theme {
  display: flex;
  align-items: center;
}
