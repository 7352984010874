@each $name, $color in $custom-colors-text {
  .#{'text-' + $name + '-emphasis'} {
    color: var(--bs-#{$name}-text-emphasis) !important;
  }
}
@each $name, $color in $custom-colors-bg-subtle {
  .#{'bg-' + $name + '-subtle'} {
    background-color: var(--bs-#{$name}-bg-subtle) !important;
  }
}

@each $name, $color in $custom-colors-border-subtle {
  .#{'border-' + $name + '-subtle'} {
    border-color: var(--bs-#{$name}-border-subtle) !important;
  }
}
