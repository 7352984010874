// Config
@import 'config';

// Functions
@import 'functions/all';

// Mixins
@import 'mixins/all';

// Vendor
// @import 'swiper/swiper';
// @import 'swiper/modules/autoplay';
// @import 'swiper/modules/free-mode';

.swiper {
  overflow: initial;
}
// @import 'quill/dist/quill.core.css';
// @import 'quill/dist/quill.snow.css';
// @import './material/all';

// Applying config to base tags etc.
@import 'base/all';

@import 'utils/all';
@import 'components/all';

// Angular components
@import 'src/app/app.module.scss';

@import './themes/all';
