// @import '../../../static/scss/config/all';

.home-header {
  > .container {
    position: relative;
    z-index: 5;
  }
  &__graphic {
    display: flex;
    align-items: center;
    justify-content: center;

    fstr-icon {
      fill: currentColor;
      width: 420px;
      height: 100px;

      @include media-breakpoint-down(md) {
        width: 200px;
        height: 50px;
      }
    }
  }

  &__intro {
    @include media-breakpoint-down(md) {
      font-size: 1.25rem;
    }
  }

  &__title {
    position: absolute;
    padding-top: 0rem;
    text-align: center;
    z-index: 5;
    width: 100%;
    letter-spacing: 0.4ex;

    background: $gradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @include media-breakpoint-down(md) {
      top: 1rem;
      width: 100%;
      max-width: 100%;
    }
  }

  &__crop {
    position: relative;
    z-index: 2;
    &__img {
      position: relative;
      overflow: hidden;

      &::before {
        pointer-events: none;
        content: '';
        width: 100%;
        height: calc(100% - 6px);
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        background-color: var(--bs-dark);
        opacity: 0.6;
      }
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      aspect-ratio: var(--aspect-ratio);

      @include media-breakpoint-down(md) {
        aspect-ratio: 16/9;
      }
    }
  }
}
