.block-news {
  // --block-spacing: 40px;
  // padding: var(--block-spacing);
  @include media-breakpoint-down(lg) {
    overflow: hidden;
    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      margin-right: -1rem;

      &__item {
        width: calc(50% - 1rem);
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      // grid-template-rows: repeat(2, 1fr);
      grid-auto-rows: minmax(min-content, max-content);
      grid-gap: 0 2rem;
      margin-bottom: -3rem;
    }

    &__list__item {
      grid-row: 2;
      grid-column: 3;

      &:first-child {
        grid-column: 1 / span 2;
        // grid-row: 1 ;
        grid-row: 1;
        margin-bottom: -4rem;

        .card {
          overflow: inherit;
          figure {
            margin-left: -7rem;
            width: calc(100% + 7rem);
          }
        }
      }

      &:first-child,
      &:nth-child(2) {
        .card-title {
          @include get-font-size(4);
        }
        img {
          height: 400px;
          object-fit: cover;
        }
      }

      &:nth-child(2) {
        grid-column: 3 / 6;
        grid-row: 1;

        .card {
          flex-direction: row;
          gap: 2rem;
          height: auto;
        }
      }

      &:nth-child(3) {
        // grid-column: 2;
        grid-row: 2;
      }

      &:nth-child(4) {
        grid-column: 4;
      }

      &:nth-child(5) {
        grid-column: 5;
      }
    }
  }

  .card {
    height: 100%;
  }
}
