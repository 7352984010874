@mixin button-icon() {
  @include size(rem($button-size));

  display: inline-flex;
  justify-content: center;
  padding: 0;

  fstr-icon {
    margin-inline-start: 0;
  }

  span + fstr-icon {
    margin-inline-end: 0;
  }
}

.button-icon {
  @include button-icon;
}
