.principle__figure {
  &.principle--1 {
    .tip--11 {
      transform: translate(300%, -300%);

      @include media-breakpoint-down(md) {
        .tooltip__content {
          left: -100%;
        }

        .tooltip__content::after {
          left: 73%;
        }
      }
    }

    .tip--12 {
      transform: translate(-150%, -250%);
    }

    .tip--13 {
      transform: translate(30%, -50%);
    }

    .tip--14 {
      transform: translate(-100%, 150%);
    }
  }

  // Smart
  &.principle--2 {
    .tip--27 {
      transform: translate(-150%, -250%);
    }

    .tip--210 {
      transform: translate(30%, -50%);
    }

    .tip--211 {
      transform: translate(-100%, 150%);
    }
  }

  // Circular
  &.principle--3 {
    .tip--36 {
      transform: translate(-200%, -220%);
    }
    .tip--38 {
      transform: translate(30%, -50%);
    }
    .tip--39 {
      transform: translate(-100%, 150%);
    }
  }

  &.principle--5 {
    .tip--41 {
      transform: translate(-200%, 200%);
    }
  }
}
