@each $name, $colors in $button-styles {
  $font-color: nth($colors, 1);
  $bg-color: nth($colors, 2);

  .button.button--#{$name} {
    --button-background: #{$bg-color};
    // --button-background-darker: #{darkenColor($bg-color, 0.2)};
    // --button-background-alpha: #{rgba($bg-color, 0.2)};
    --button-background-darker: color-mix(in srgb, #{$bg-color} 70%, black);
    --button-color: #{$font-color};
  }
}

.button.button--dark {
  --button-background-darker: var(--bs-dark);
}

.button.button--light {
  --button-background-darker: var(--bs-light);

  &:hover {
    --button-color: var(--bs-dark);
  }
}
