.content-overview {
  .header main {
    max-width: 80%;
    color: var(--bs-light);
  }

  &__list__item {
    .card {
      height: 100%;
      img {
        object-fit: cover;
      }
    }
    &:first-child {
      .card {
        @include media-breakpoint-up(md) {
          gap: 2rem;
          overflow: inherit;
          flex-direction: row;

          &-body {
            width: calc(50% - 4rem);
            margin: 0 1rem;
          }

          &-title {
            font-size: 2rem;
          }

          &-text {
            font-size: 1.25rem;
          }

          &-img-top {
            width: calc(50% + 9rem);
            height: 100%;
            margin-left: -10rem;

            img {
              aspect-ratio: 16/10;
            }
          }
        }
      }
    }

    &:nth-child(2),
    &:nth-child(3) {
      .card {
        img {
          aspect-ratio: 4/3;
        }

        &-title {
          font-size: 1.4rem;
        }
      }
    }
  }
}
