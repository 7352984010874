// Boostrap
//$box-shadow: 0 .5rem 1rem rgba(theme-color(dark), .15);
//$box-shadow-sm: 0 .125rem .25rem rgba(theme-color(dark), .075);
//$box-shadow-lg: 0 1rem 3rem rgba(theme-color(dark), .175);
//$box-shadow-inset: inset 0 1px 2px rgba(theme-color(dark), .075);
$box-shadow: 0 0.1rem 1rem rgba($gray-900, 0.15);
$box-shadow-sm: 0 0.25rem 1rem rgba($gray-900, 0.2);
$box-shadow-lg: 0 0.5rem 2rem rgba($gray-900, 0.2);
$box-shadow-xl: 0 2rem 2rem rgba($gray-900, 0.1);
$box-shadow-inset: inset 0 1px 2px rgba($gray-900, 0.075);

// Other
//? Put own extra variables here
