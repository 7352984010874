@import './principle-tooltips';

.principle-component {
  width: 100%;

  // &__nav {
  //   position: relative;
  //   z-index: 10;
  // }

  // &__main {
  //   position: relative;
  //   z-index: 9;
  // }

  &__principle {
    opacity: 0;
  }

  .tooltip {
    @include abs(50%, null, null, 50%);
    transform: translate(-50%, -50%);
    display: inline-block;

    &.set--active-item {
      z-index: 100;
    }

    .button {
      //--button-background: color-mix(in srgb, var(--bs-light) 20%, transparent);
      --button-background-darker: var(--bs-primary);
      --button-background: var(--bs-light);
      --button-color: var(--bs-primary);
      --button-border-radius: 50%;

      @media (hover: hover) {
        &:hover {
          --button-color: var(--bs-light);
        }
      }

      .icon {
        transition: transform 0.3s ease;
      }

      &.set--active {
        --button-background: var(--bs-primary);
        --button-color: var(--bs-light);
        .icon {
          transform: rotate(45deg);
        }
      }
    }

    .tooltip__content {
      @include abs(0, null, null, 0);
      @include get-font-size('body-xs');

      background-color: var(--bs-light);
      opacity: 0;
      transform: translate(calc(-90% - 1rem), -90%) scale(0);
      transform-origin: right bottom;
      transition:
        transform 0.3s ease,
        opacity 0.3s ease;
      pointer-events: none;
      padding: spacer(3);
      min-width: 320px;

      @include media-breakpoint-down(md) {
        min-width: 240px;
        width: 100%;
        left: 50%;
        z-index: 10;
      }

      &::after {
        @include abs(100%, 0, null, null);

        transform: translate(90%, -200%);
        content: '';
        border-top: transparent 8px solid;
        border-bottom: transparent 8px solid;
        border-left: 8px solid var(--bs-light);

        @include media-breakpoint-down(md) {
          top: -15px;
          left: 50%;
          right: auto;
          transform: rotate(-90deg) translate(-50%, 0);
        }
      }

      &.set--active {
        opacity: 1;
        transform: translate(calc(-100% - 1rem), -80%) scale(1);

        @include media-breakpoint-down(md) {
          transform: translate(-50%, 70px) scale(1);
          z-index: 10;
        }
      }
    }
  }

  .principle__figure {
    position: relative;
    width: 100%;
    aspect-ratio: 1/1;

    @include media-breakpoint-down(md) {
      z-index: 10;
    }

    fstr-image {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
      transition: transform 0.5s cubic-bezier(0.53, -0.92, 0.46, 1.68);

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    &.tooltip--active.show--scale-anim {
      fstr-image {
        transform: scale3d(2, 2, 2) translate3d(0, 10%, 0);
      }
    }

    // animation: blur-out 0.4s ease-in-out both;
  }

  .principle__content {
    @include media-breakpoint-down(md) {
      padding: spacer(3);
      background-color: var(--bs-light);
      margin-left: -1rem;
      margin-right: -1rem;
    }
  }
}

.anim--fadeIn {
  animation: fade-in 0.2s ease-in-out both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
