// Bootstrap

$white: #fff;
$gray-100: #151b20;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #005ba6;
$blue-200: #37b1e6;
$indigo: #6610f2;
$purple: #37307d;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #dedd00;
$green: #97c22c;
$teal: #00a39c;
$cyan: #0dcaf0;

$primary: $purple;
$secondary: $green;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: #fff;
$dark: $gray-900;

// add custom colors in this map and also check _color-maps.scss for further implementation
$accent: $teal;
$custom-theme-colors: (
  'accent': $accent,
);

$primary-text-emphasis: shade-color($primary, 60%);
$secondary-text-emphasis: shade-color($secondary, 60%);
$success-text-emphasis: shade-color($success, 60%);
$info-text-emphasis: shade-color($info, 60%);
$warning-text-emphasis: shade-color($warning, 60%);
$danger-text-emphasis: shade-color($danger, 60%);
$light-text-emphasis: $gray-700;
$dark-text-emphasis: $gray-700;

$primary-bg-subtle: tint-color($primary, 80%);
$secondary-bg-subtle: tint-color($secondary, 80%);
$success-bg-subtle: tint-color($success, 80%);
$info-bg-subtle: tint-color($info, 80%);
$warning-bg-subtle: tint-color($warning, 80%);
$danger-bg-subtle: tint-color($danger, 80%);
$light-bg-subtle: mix($gray-100, $white);
$dark-bg-subtle: $gray-400;

$primary-border-subtle: tint-color($primary, 60%);
$secondary-border-subtle: tint-color($secondary, 60%);
$success-border-subtle: tint-color($success, 60%);
$info-border-subtle: tint-color($info, 60%);
$warning-border-subtle: tint-color($warning, 60%);
$danger-border-subtle: tint-color($danger, 60%);
$light-border-subtle: $gray-200;
$dark-border-subtle: $gray-500;

// Other
//? Put own extra variables here
$body-secondary-color: $gray-400;
$body-bg: $blue-200;

// $gradient: radial-gradient(104.99% 63.95% at 100% 100%, rgba($white, 0.2) 0%, transparent 100%);
$gradient-lg: radial-gradient(109.67% 144.8% at 73.73% 67.98%, rgba($white, 0.2) 0%, rgba($white, 0) 100%);

$gradient: linear-gradient(268.9deg, #dedd00 -9.58%, #37b1e6 112.98%);
