fstr-icon {
  position: relative;
  display: inline-flex;
  min-width: 1.5em;
  min-height: 1.5em;
  fill: currentColor;

  .icon {
    @include abs(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    fill: inherit;

    &[class*='is--line-icon'] * {
      fill: transparent;
    }
  }
}
